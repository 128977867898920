import * as dev from './env.development'
import * as prod from './env.production'

// Environment dependent
export const { SERVER_ADDRESS, IS_DEVELOPMENT } = (process.env.NODE_ENV == 'production') ? (prod) : (dev)

// General
export const SERVER_ENDPOINT_NEW_VISITOR = `${SERVER_ADDRESS}/api/v1/newVisitor`
export const SERVER_ENDPOINT_ACTIVE_VISITORS = `${SERVER_ADDRESS}/api/v1/activeVisitors`
export const SERVER_ENDPOINT_VIEW_REQUEST = `${SERVER_ADDRESS}/api/v1/getVisitor`
export const SERVER_ENDPOINT_MINT_REQUEST = `${SERVER_ADDRESS}/api/v1/mintRequest`

export const TZKT_URL_PREFIX = `https://tzkt.io`
export const OBJKT_URL_PREFIX = `https://objkt.com/asset`
export const OBJKT_COLLECTION_NAME = `life-after-bob`

export const NFT_STATE = {
  Ready: "Ready",
  Viewed: "Viewed",
  Minting: "Minting",
  Minted: "Minted",
  MintRequestError: "MintRequestError"
}

export const READY_TO_DISPLAY_STATE = {
  NotReady: "Not_Ready",
  Ready: "Ready"
}

console.log('Server: ', SERVER_ADDRESS)
