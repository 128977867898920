import 'react-dropdown/style.css';
import React from 'react';
import './Footer.css'
import MetisSunsLogo from '../assets/img/metissuns-logo.png'
import classNames from 'classnames';

class Footer extends React.Component {
  render() {
    const { inverted } = this.props
    const cls = classNames({ "footer": true, "inverted": inverted })
    return (
      <div className={cls}>
        <a href="http://metissuns.com" target="_blank">
          <img src={MetisSunsLogo}/>
        </a>
      </div>
    );
  }
}

export default Footer