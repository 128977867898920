import 'react-dropdown/style.css';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../components/withRouter';
import './MintAll.css'
import { Link } from 'react-router-dom';
import MetisSunsLogo from '../assets/img/metissuns-logo.png'
import Footer from '../components/Footer';
import Header from '../components/Header';
import classNames from 'classnames';
import delay from 'delay';

class MintLanding extends React.Component {
  constructor(props) {
    super(props)
    this._inputs = [null, null, null, null, null]
  }

  state = {
    inputCode: '',
    inputDigits: ["", "", "", "", ""],
    readMoreActive: 0,
    appHidden: false
  }

  onInputChange = (index) => (e) => {
    let val = e.target.value    
    let character = ""
    if (val.length > 0) {
      character = val[val.length - 1]
    }
    let newDigits = [...this.state.inputDigits]
    newDigits[index] = character.toLowerCase()
    this.setState({ inputDigits: newDigits }, () => {
      if (character.length == 0) return
      if (index == 4) {
        this._inputs[index].blur()
      } else {
        if (this._inputs[index + 1]) {
          this._inputs[index + 1].focus()
        }  
      }
    })
  }

  getIsReady = () => {
    const { inputDigits } = this.state    
    let isReady = true
    for (let i = 0; i < inputDigits.length; i++) {
      if (inputDigits[i].length != 1) isReady = false
    }
    return isReady
  }

  onReadMore = () => {
    this.setState({ readMoreActive: 1 }, async () => {
      await delay(100)
      this.setState({ readMoreActive: 2 })
    })
  }

  onKeyPress = (e) => {
    if (e.keyCode != 13) return
    if (!this.getIsReady()) return
    const { inputDigits } = this.state
    this.props.navigate(`/mint/${inputDigits.join('')}`)
  }

  componentDidMount() {
    window.addEventListener("keypress", this.onKeyPress)
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.onKeyPress)
  }

  render() {
    const { inputCode, inputDigits, readMoreActive, appHidden } = this.state
    const userError = this.props.location.search.includes("userError")
    const { t } = this.props

    let inputClassnames = []
    for (let i = 0; i < inputDigits.length; i++) {
      inputClassnames.push(classNames({
        "code-input-new": true,
        "filled": inputDigits[i].length > 0
      }))
    }

    let isReady = this.getIsReady()
    let buttonClassnames = classNames({
      "submit-button": true,
      "submit-code": true,
      "interactive": isReady,
      "disabled": !isReady
    })
    let linkClassnames = classNames({ "link-disabled": !isReady })

    let readMoreClassnames = classNames({ "information-more": true, interim: (readMoreActive == 1), visible: (readMoreActive == 2) })
    let readMoreButtonClassnames = classNames({ "read-more": true, "interactive": true, "hidden": (readMoreActive > 0) })

    let containerClassnames = classNames({
      "app-container": true,
      "hidden": appHidden
    })
    return (
      <div className={containerClassnames}>
        <div className="content home-content-container">
          <Header/>

          <div className="section submit-container">
            <div className="text-instructions">
              <strong>{t("landing_welcome")}</strong>
              <br/>
              <br/>
              <strong>{t("landing_accessInstructions")}</strong>
            </div>
            { userError &&
              <div className="text-instructions error">
                <h4>{t("landing_codeNotFound")}</h4>            
              </div>
            }
            <div className="code-input">
              <div>
                <input ref={i => { this._inputs[0] = i }} className={inputClassnames[0]} type="text" value={inputDigits[0]} onChange={this.onInputChange(0)}/>
                <input ref={i => { this._inputs[1] = i }} className={inputClassnames[1]} type="text" value={inputDigits[1]} onChange={this.onInputChange(1)}/>
                <input ref={i => { this._inputs[2] = i }} className={inputClassnames[2]} type="text" value={inputDigits[2]} onChange={this.onInputChange(2)}/>
                <input ref={i => { this._inputs[3] = i }} className={inputClassnames[3]} type="text" value={inputDigits[3]} onChange={this.onInputChange(3)}/>
                <input ref={i => { this._inputs[4] = i }} className={inputClassnames[4]} type="text" value={inputDigits[4]} onChange={this.onInputChange(4)}/>
              </div>
              
              <Link to={`/mint/${inputDigits.join('')}`} className={linkClassnames}>
                <div className={buttonClassnames}></div>
              </Link>
            </div>
          </div>

          <div className="section information-container">
            <div dangerouslySetInnerHTML={{ __html: t("landing_aboutParagraphOne") }}>              
            </div>

            <br/>

            <div className={readMoreButtonClassnames} onClick={this.onReadMore}>{t("landing_readMore")} &#8595;</div>

            <div className={readMoreClassnames} dangerouslySetInnerHTML={{ __html: t("landing_aboutParagraphTwo") }}>              
            </div>

          </div>

          <Footer/>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(MintLanding))